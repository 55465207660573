import React, { useContext, useState } from "react";
import { Button, Form, Input, InputNumber, notification, Select, Spin, Tooltip } from "antd";
import { FormInstance } from "antd/es/form";
import { openErrorNotification } from "../../../_heplers/NotificationHelper";
import { validateMessages } from "../../../_common/ValidationCominications";
import { withdrawRequest } from "../../../_services/wire/WithdrawService";
import "./TransferForm.scss";
import { InfoCircleOutlined } from "@ant-design/icons";
import { AppContext } from "../../../_common/app_context_provider/AppContextProvider";

interface TransferFormProps {
  showModal: boolean;
  closeModal: () => void;
  CompanyIban?: string;
  siteUuid: string;
  currency: string;
}

const TransferForm: React.FC<TransferFormProps> = ({ showModal, closeModal, CompanyIban, siteUuid, currency }) => {
  const [loading, setLoading] = useState(false);
  const context = useContext(AppContext);
  const [form] = Form.useForm<FormInstance>();
  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      const response = await withdrawRequest(
        siteUuid,
        currency,
        Number(values.amount),
        values.description,
        values.virtualIban,
        {
          firstName: values.firstName,
          lastName: values.lastName,
          accountNumber: values.accountNumber,
          bic: values.bic,
          beneficiaryType: values.beneficiaryType,
          city: values.city,
          email: values.email,
          companyName: values.companyName,
          country: values.country,
          postalCode: values.postalCode,
          addressLine1: values.addressLine1,
          addressLine2: values.addressLine2,
        },
      );
      // @ts-ignore
      if (response?.data?.success) {
        notification.success({
          message: 'Withdrawal submitted',
          description: 'Your withdrawal request has been received and is being processed.',
          placement: 'topRight',
        });        
        form.resetFields(); // Reset form fields on success
        closeModal(); // Close modal
      } else (
        // @ts-ignore
        handleErrors(response)
      )
    } catch (error: any) {
      handleErrors(error?.response?.data)
    } finally {
      setLoading(false);
    }
  };

  /**
 * Function to handle different error responses
 */
  const handleErrors = (response: any) => {
    if (!response) {
      openErrorNotification("Unknown error occurred. Please try again.");
      return;
    }

    // Case 1: Standard validation errors (missing fields)
    if (response.errors && typeof response.errors === "object") {
      Object.entries(response.errors).forEach(([field, messages]) => {
        const errorMessages = Array.isArray(messages) ? messages : [messages];

        errorMessages.forEach((message) => {
          openErrorNotification(`${field.replace("receiver.", "").replace("sender.", "")}: ${message}`);
        });
      });

      return; // Stop execution here if handled
    }

    // Case 2: API error response with error array (e.g., invalid virtual IBAN)
    if (response.data?.errors && Array.isArray(response.data.errors)) {
      response.data.errors.forEach((error: any) => {
        openErrorNotification(error.message || "An unknown error occurred.");
      });

      return; // Stop execution here if handled
    }

    // Case 3: Fallback error message
    openErrorNotification("Withdrawal request failed. Please check your input and try again.");
  };




  return (
    <Spin spinning={loading}>
      <div className="transfer-form-wrapper">
        <Form
          form={form}
          layout="horizontal"
          name="transfer-form"
          onFinish={onFinish}
          validateMessages={validateMessages()}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ virtualIban: CompanyIban }}
        >
          {/* Amount */}
          <Form.Item label="Amount" name="amount" rules={[{ required: true, message: "Amount is required" }]}>
            <InputNumber<number>
              style={{ width: "100%" }}
              min={0}
              step={0.01}
              precision={2}
              placeholder="Enter amount"
              addonAfter={currency || ""}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              parser={(value) => parseFloat(value?.replace(/,/g, "") || "0")}
              onWheel={(e) => e.currentTarget.blur()}

            />
          </Form.Item>

          {/* Description */}
          <Form.Item label="Description" name="description" rules={[{ required: true, message: "Description is required" }]}>
            <Input placeholder="Enter Transaction Description" />
          </Form.Item>

          <h3>Sender Information</h3>
          <Form.Item label="Sender Account" name="virtualIban">
            <Input defaultValue={CompanyIban} value={CompanyIban} disabled />
          </Form.Item>

          <h3>Receiver Information</h3>

          <Form.Item
            label="First Name"
            name="firstName"
            rules={[{ required: true, message: "First name is required" }]}
          >
            <Input placeholder="Enter first name" />
          </Form.Item>

          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[{ required: true, message: "Last name is required" }]}
          >
            <Input placeholder="Enter last name" />
          </Form.Item>

          <Form.Item
            label="Company Name"
            name="companyName"
            rules={[{ required: true, message: "Company name is required" }]}
          >
            <Input placeholder="Enter company name" />
          </Form.Item>




          {/* Shared Receiver Fields */}
          <Form.Item label="Account Number" name="accountNumber" rules={[{ required: true }]}>
            <Input placeholder="Enter account number" />
          </Form.Item>

          <Form.Item label="Email" name="email" rules={[{ type: "email" }]}>
            <Input placeholder="Enter email" />
          </Form.Item>


          <Form.Item label="Address Line 1" name="addressLine1">
            <Input placeholder="Enter address line 1" />
          </Form.Item>

          <Form.Item label="Address Line 2" name="addressLine2">
            <Input placeholder="Enter address line 2" />
          </Form.Item>

          <Form.Item label="Postal Code" name="postalCode">
            <Input placeholder="Enter postal code" />
          </Form.Item>

          <Form.Item label="City" name="city" rules={[{ required: true }]}>
            <Input placeholder="Enter city" />
          </Form.Item>

          <Form.Item
            label="Country"
            name="country"
            rules={[{ required: true, message: "Country is required" }]}
          >
            <Select
              showSearch
              placeholder="Select a country"
              optionFilterProp="label"
              filterOption={(input, option) =>
                (option?.label as string).toLowerCase().includes(input.toLowerCase())
              }
            >
              {context.dictionaries.countries.map((country: any) => (
                <Select.Option key={country.value} value={country.value} label={country.name}>
                  {country.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>


          <Form.Item label="BIC" name="bic" rules={[{ required: true }]}>
            <Input placeholder="Enter BIC" />
          </Form.Item>

          {/* Submit / Cancel */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "20px",
              width: "100%",
            }}
          >
            <Button
              className="common_cancel_button"
              onClick={closeModal}
              style={{ flex: 1, maxWidth: "48%", height: "50px" }}
            >
              Cancel
            </Button>
            <Button
              className="common_submit_button"
              type="primary"
              onClick={() => form.submit()}
              disabled={loading}
              style={{ flex: 1, maxWidth: "48%", height: "50px" }}
            >
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </Spin>
  );
};

export default TransferForm;


