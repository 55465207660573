import Axios from 'axios';
import { clearLocalStorage, getToken } from '../_heplers/LocalStorageHelpers';
import { openErrorNotification } from '../_heplers/NotificationHelper';
import jwt_decode from "jwt-decode";
import { refreshToken, revokeToken } from './auth/AuthenticationServices';

const axios = Axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    }
});
export const auth = Axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
    }
});
axios.interceptors.request.use(
     async (config: any) => {
        let jwt = getToken();
        if (jwt) {
            let decodedToken = jwt_decode(jwt) as {
                'id': string,
                "hash": string,
                "nbf": number,
                "exp": number,
                "iat": number
              };
            let currentDate = new Date();
            // JWT exp is in seconds check if expired
            if (decodedToken?.exp * 1000 < currentDate.getTime()) {
                await refreshToken().then((response: any) => {
                    localStorage.setItem('jwt', response.token);
                    jwt = response.token;
                }).catch(async (e) =>{
                    console.log(e, ' error');
                    // Clear localstorage
                    clearLocalStorage();
                    await revokeToken();
                    //refresh in order to send to login page
                    window.location.href = '/login';
                })
            }
            config.headers.Authorization = `Bearer ${jwt}`
        }
        return config
    },
    (error: any) => Promise.reject(error)
)
axios.interceptors.response.use(
    (response: any) => response,
    async (error: any) => {
        if (
            error?.response?.status === 401
        ) {
            // Clear localstorage
            clearLocalStorage();

            // TO Do: this is one of the common error
            //  openErrorNotification('No session or session is expired. Please login.');
            await revokeToken();
            //refresh in order to send to login page
            window.location.href = '/login';

            return;
        }
        else if (error?.response?.status === 403) {
            openErrorNotification("You don't have permission for this action.");
        }
        else if (error) {
            // TO Do: this is one of the common error
            openErrorNotification('An error occurred')
        }
        return Promise.reject(error)
    });

export default axios;
