import axios from '../api';

const withdrawUrl = 'api/WireApi/Withdraw';

export const withdrawRequest = async (
    apiKey: string,
    currency: string,
    amount: number,
    title: string,
    senderVirtualIban: string,
    receiverData: {
      firstName: string;
      lastName: string;
      accountNumber: string;
      bic: string;
      beneficiaryType: number;
      city: string;
      email: string;
      companyName: string;
      country: string;
      postalCode: string;
      addressLine1: string;
      addressLine2?: string;
    },
    externalId?: string
  ) => {
    const payload = {
      currency,
      amount,
      title,
      receiver: receiverData,
      sender: {
        virtualIban: senderVirtualIban, // Ensure this is correctly passed
      },
      externalId: externalId || '',
    };
    return await axios.post(withdrawUrl, payload, {
        params: { apiKey },
      });
  };
  
