import axios from '../api';

const url = 'api/UserApi';

// get users list
export const getUsersServices = () =>
  axios.get(`${url}/GetUsers`)

// get data of single user
export const getUser = (userId: any) =>
  axios.get(`${url}/GetUser`, { params: { userId } })
// update single user
export const updateUser = async (payload: any) =>
  await axios.post(`${url}/Update`, payload)

// create single user
export const createUser = async (payload: any) =>
  await axios.post(`${url}/Create`, payload)

// Toggle user availability
export const setUserDisabled = async (payload: any) =>
  await axios.get(`${url}/SetUserDisabled`, { params: { userId: payload.userId, disabled: payload.disabled } })

export const checkIfAdminUserExist = (email: string, siteUuid?: string) =>
  axios.get(`${url}/CheckIfAdminUserExist`, { params: { email, siteUuid }})

