import styles from '../forms/styles/InputFormStyle.module.scss';
import { IInputForm } from '../../interfaces/components/IInputForm';
import { Input, Tooltip } from 'antd';
import {
    QuestionCircleOutlined
} from '@ant-design/icons';

const InputForm = (props: IInputForm) => {
    return (
        <>
            {props.label ?
                <div style={{display: 'flex'}}><label className={styles.input_form_label}>{props.label}</label>
                    {props.showTooltip && <Tooltip placement={props.placement} arrowPointAtCenter title={props.tootlipTitle}> <QuestionCircleOutlined style={{ color: '#6D6D6D' }} />
                    </Tooltip>}
                </div>
            : true}
           
            <Input
                className={`${styles.input_form} ${props.className!}`}
                placeholder={props.placeholder!}
                value={props.value!}
                type={props.type!}
                disabled={props.disabled}
                onChange={props.onChange}
                onBlur={props.onBlur}
                />
        </>
    );
}

export default InputForm;
