import InputForm from '../../../../_common/components/forms/InputForm';
import React,{useState} from 'react';
import { Button, Form, Input } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { IMobilumPartnerDetailsUserInformationFormProps } from '../interfaces/IMobilumPartnerDetailsUserInformationFormProps';
import { validateMessages } from '../../../../_common/ValidationCominications';
import { checkIfAdminUserExist } from '../../../../_services/users/UsersServices';


export class MobilumPartnerDetailsUserInformationForm extends React.Component<IMobilumPartnerDetailsUserInformationFormProps> {
    _form_ref = React.createRef<FormInstance>();

    handleBlurEmail = (rule: any, value: string, callback: (error?: string) => void) => {
        const email = value.trim();
        if (email !== '') {
            checkIfAdminUserExist(email, this.props.mobilum_partner_details.siteUuid)
                .then((response) => {
                    if (response.status === 200) {
                        console.log(response);
                        if(response.data){
                            callback('This email already exists.'); // Pass error message to callback
                        }else{
                            callback();
                        }
                    } else {
                        console.log('user does not exist');
                        callback(); // No error, call callback without arguments
                    }
                })
                .catch((error) => {
                    console.error('Error fetching user by email:', error);
                    callback('Error checking email. Please try again.'); // Pass error message to callback
                });
        } else {
            // Empty email, no need to make a request
            callback(); // No error, call callback without arguments
        }
    };
    
    
    public render() {
        return (
            <Form
                layout='vertical'
                name='control-ref'
                ref={this._form_ref}
                onFinish={this.onFinish}
                validateMessages={validateMessages()}
                initialValues={{ ...this.props.mobilum_partner_details }}
                className='flex' style={{ flexDirection: 'column', height: '100%' }}
            >
                <div className='column_description'>{this.props.title}</div>
                <div className='grid_col_2' style={{ marginBottom: 8 }}>
                    <Form.Item
                        name='adminName'
                        label='First Name'
                        rules={[{ required: true }]}
                    >
                        <InputForm
                            placeholder='Thomas'
                        />
                    </Form.Item>
                    <Form.Item
                        name='adminSurname'
                        label='Last Name'
                        rules={[{ required: true }]}
                    >
                        <InputForm
                            placeholder='Muller'
                        />
                    </Form.Item>
                </div>
                <Form.Item
                    name='adminEmail'
                    label='Email'
                    rules={[
                        { type: 'email' },
                        { required: true },
                        {
                            validator: this.handleBlurEmail, // Use handleBlurEmail as the validator
                        }
                    ]}
                >
                    <InputForm
                        //onBlur={this.handleBlurEmail} // Bind handleBlurEmail directly to onBlur event
                        placeholder='i.e, thomas@gmail.com'
                    />
                </Form.Item>
                <div className='flex grid_col_2' style={{ marginTop: 'auto' }}>
                    <Button
                        className='common_previous_button'
                        onClick={() => this.props.setCurrentStep(0)}
                    >
                        Previous
                    </Button>
                    <Form.Item shouldUpdate={true}>
                        {() => (
                            <Button
                                type='primary'
                                htmlType='submit'
                                className='common_next_button'
                                disabled={this.showSuimtuBtton()}
                            >
                                Next
                            </Button>
                        )}
                    </Form.Item>
                </div>
            </Form>
        );
    }

    showSuimtuBtton = () => {
        if (this._form_ref.current) {
            const errors = this._form_ref.current
                .getFieldsError()
                .filter(({ errors }) => errors.length).length;

            // Check if error
            if (errors) {
                return true;
            }
            else {
                return false;
            }
        }

        return true;
    };

    onFinish = (values: any) => {
        let {
            setCurrentStep,
            updateState,
            mobilum_partner_details
        } = this.props;

        mobilum_partner_details = { ...mobilum_partner_details, ...values };

        updateState(mobilum_partner_details);
        setCurrentStep(2);
    }
}
