import React, { ChangeEvent, ReactNode, useState } from 'react';
import {
    Button,
    Modal
} from 'antd';
import styles from '../../notifications/styles/Notifications.module.scss';


interface ModalPropsType {
  showModal: boolean;
  closeModal: () => void;
  onSubmit?: () => void; // Make optional
  title: string;
  children?: ReactNode; // Accept children
  submitButtonText?: string
  showFooter?: boolean;
  disableSubmit?: boolean; 
}

export const MobilumModal: React.FC<ModalPropsType> = ({ title, showModal, closeModal, onSubmit, children, submitButtonText = 'Submit', showFooter = false, disableSubmit }) => {


  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit();
    }
    closeModal(); // Close the modal
  };

  const handleCancel = () => {
    closeModal(); // Close the modal
  };

  return (
    <Modal title={title} visible={showModal} footer={null} width={600} destroyOnClose onCancel={handleCancel}>
      <div className={styles.modal_content}>
        <div className={styles._modal_title} style={{ marginBottom: "30px" }}>
          {title}
        </div>
        {children}
        {/* Footer Buttons */}
        {showFooter && (
          <div className="grid_col_2">
            <Button className="common_cancel_button" onClick={handleCancel}>
              Cancel
            </Button>
            <Button className="common_submit_button" type="primary" disabled={disableSubmit} onClick={handleSubmit}>
              {submitButtonText}
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};
